var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loan.bankstatement.length > 0)?_c('div',{staticClass:"card-body"},[_c('b-row',{staticClass:"align-items-center justify-content-start"},[_c('b-col',{attrs:{"sm":"1","md":"1","lg":"1"}}),_c('b-col',{attrs:{"sm":"6","md":"4","lg":"3"}},[_c('b',[_vm._v(" "+_vm._s(_vm.$t("loansPage.LoanDetails.expiration-card.bank-name")))])]),_c('b-col',{attrs:{"sm":"6","md":"4","lg":"3"}},[_c('b',[_vm._v(" "+_vm._s(_vm.$t("loansPage.LoanDetails.expiration-card.expiration-date")))])]),_c('b-col',{attrs:{"sm":"6","md":"4","lg":"2"}},[_c('b',[_vm._v(" "+_vm._s(_vm.$t("loansPage.LoanDetails.expiration-card.expiration-day")))])]),_c('b-col',{attrs:{"sm":"6","md":"4","lg":"2"}})],1),_vm._l((_vm.sortedBankStatements),function(bankstatement){return _c('b-row',{key:bankstatement.id,staticClass:"align-items-center justify-content-start",class:{ 'pb-2': _vm.loan.bankstatement.length > 1 }},[_c('b-col',{staticClass:"p-0",attrs:{"sm":"1","md":"1","lg":"1"}},[_c('img',{attrs:{"src":bankstatement.bankImage,"width":"70"},on:{"error":function($event){return _vm.setFallbackImage($event)}}})]),_c('b-col',{attrs:{"sm":"6","md":"4","lg":"3"}},[_vm._v(" "+_vm._s(bankstatement.bankName)+" ")]),_c('b-col',{attrs:{"sm":"6","md":"4","lg":"3"}},[_vm._v(" "+_vm._s(new Date(bankstatement.consent_expiration).toLocaleDateString())+" ")]),_c('b-col',{attrs:{"sm":"6","md":"4","lg":"2"}},[_vm._v(" "+_vm._s(_vm.getDayLeft(bankstatement))+" ")]),_c('b-col',{attrs:{"sm":"6","md":"3"}},[_c('b-button',{staticClass:"my_btn",class:{
          red: _vm.getDayLeft(bankstatement) <= 30,
          yellow:
            _vm.getDayLeft(bankstatement) > 30 && _vm.getDayLeft(bankstatement) < 60,
          green: _vm.getDayLeft(bankstatement) >= 60,
        },on:{"click":function($event){_vm.renewConsent(
            bankstatement.partner_user_id,
            bankstatement.user_id,
            bankstatement.bank_id
          ),
            (_vm.isLoading = true)}}},[_vm._v(" "+_vm._s(_vm.$t("loansPage.LoanDetails.expiration-card.renew-consent"))+" ")])],1)],1)}),(_vm.isLoading)?_c('div',{staticClass:"over"},[_vm._m(0)]):_vm._e()],2):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spinner-border text-warning",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])}]

export { render, staticRenderFns }